<template>
  <div class="container">
    <edit-header
      entity="category"
      :error.sync="error"
      :invalid.sync="invalid"
      :submitted.sync="submitted"
      :isNew="isNew"></edit-header>
    <form
      v-disable-all="!can(uiPermissions.CATEGORIES_UPDATE)"
      ref="form"
      class="validation"
      novalidate
      @submit.prevent="submit">
      <div class="row">
        <div class="col">
          <div data-test="card-category" class="card">
            <div class="card-header">
              <h3 class="card-title">Category tree</h3>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label class="form-label">Parent category</label>
                <input
                  v-model="item.name"
                  type="text"
                  data-test="input-parent-category"
                  class="form-control"
                  :class="{ 'is-invalid': hasErrorResponse }"
                  required>
                <small v-if="hasErrorResponse" class="error text-danger">{{response.error.name.join(', ')}}</small>
              </div>
              <div class="form-group">
                <textarea
                  v-model="item.description"
                  data-test="input-description"
                  class="form-control"
                  placeholder="Description (Optional)"></textarea>
              </div>

              <div
                v-if="item.name.length"
                data-test="div-sub-category"
                class="pl-6">
                <label
                  v-if="item.sub_categories.length"
                  data-test="label-sub-category"
                  class="form-label">Sub-category
                </label>
                <ul class="category-tree">
                  <category-tree
                    v-for="(child, index) in item.sub_categories"
                    :key="index"
                    class="item"
                    :entity="`category`"
                    :item="child"
                    :index="index"
                    :response="response"
                    :parent="item"
                    @make-folder="makeFolder"
                    @add-item="addItem"
                    @remove-item="removeItem"></category-tree>
                  <li v-if="item.sub_categories.length" class="pl-5">
                    <button
                      data-test="btn-add-sub-category"
                      class="add btn btn-primary btn-sm mt-4"
                      @click.prevent="addItem(item)">
                      <i class="fe fe-plus"></i> Add sub-category for
                      {{item.name}}
                    </button>
                  </li>
                </ul>
              </div>
              <button
                v-if="!item.sub_categories.length && item.name.length"
                data-test="btn-add-first-sub-category"
                class="add btn btn-primary btn-sm mt-4"
                @click.prevent="addItem(item)">
                <i class="fe fe-plus"></i> Add sub-category for {{item.name}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <edit-footer
            :dirty="dirty"
            entity="category"
            :isNew="isNew"
            :submitting="submitting"
            :hide-delete="true"></edit-footer>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import CategoryTree from '@/components/CategoryTree';
import edit from '@/mixins/edit';
import {categories} from '@/services';

export default {
  components: {
    CategoryTree,
    EditFooter,
    EditHeader,
  },
  mixins: [
    edit,
  ],
  data() {
    return {
      item: {
        description: '',
        name: '',
        sub_categories: [],
      },
      removed: [],
    };
  },
  computed: {
    hasErrorResponse() {
      return this.response.error?.name && this.response.data.index === null;
    },
    route() {
      return `/categories/${this.item.id}`;
    },
  },
  methods: {
    addItem(item) {
      item.sub_categories.push({
        name: '',
        parent_id: item.id ? item.id : null,
        sub_categories: [],
      });
    },
    fetchData(id) {
      return categories.getById(id);
    },
    async handleDelete() {
      if (window.confirm('Deleting category cannot be undone. Are you sure that you want to delete this category?')) {
        await categories.deleteById(this.item.id);
        this.$router.push('/categories');
      }
    },
    makeFolder(item) {
      this.$set(item, 'sub_categories', []);
      this.addItem(item);
    },
    async removeItem({parent, index}) {
      const subCategory = parent.sub_categories[index];

      if (subCategory && subCategory.id && window.confirm(`Deleting ${subCategory.name} sub-category cannot be undone. Are you sure that you want to delete this sub-category?`)) {
        await categories.deleteById(subCategory.id);
        this.reset();
      }

      if (!subCategory.id) { parent.sub_categories.splice(index, 1); }
    },
    async submitData(item) {
      const payload = {

        description: item.description,
        index: null,
        name: item.name,
        parent_id: null,
      };

      if (item.id) { payload.id = item.id; }

      this.response.data = payload;

      const parent = await categories.saveOrUpdate(payload);
      this.item.id = parent.item.id;

      // delete all removed sub categories
      if (this.removed.length) { await Promise.all(this.removed); }

      // recursively update all sub categories
      if (item.sub_categories.length) { await this.submitSubcategories(item.sub_categories, parent); }

      // refetch the category details
      return this.fetchData(parent.item.id);
    },
    async submitSubcategories(item, parent) {
      // use regular loop, .forEach method doesn't respect async await to run posts sequentially
      for (let i = 0; i < item.length; i++) {
        const payload = {

          index: i,
          name: item[i].name,
          parent_id: parent.item.id,
        };

        // case data that has ids already
        if (item[i].id) { payload.id = item[i].id; }

        // [mixin] set last post data for API error handling
        this.response.data = payload;

        // this will wait to finish then grab the created id
        const subParent = await categories.saveOrUpdate(payload);
        item[i].id = subParent.item.id;

        // keep recursing the function if the parent still have subcategories in the list
        // send the parent object that has the created ID
        if (item[i].sub_categories.length) { await this.submitSubcategories(item[i].sub_categories, subParent); }
      }

      // all nested sub-categories are processed stop and return true to complete the async/await
      if (item.every(data => !data.sub_categories.length)) { return true; }
    },
    transformData(result) {
      return result;
    },
  },
};
</script>

<style scoped>
ul.category-tree {
  margin: 0;
  padding: 0;
}

ul.category-tree .bold input {
  font-weight: bold;
}

ul.category-tree ul {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

ul.category-tree li {
  list-style: none;
  padding: 0.5rem 0;
}
</style>
